/*

Tooplate 2118 Chilling Cafe

https://www.tooplate.com/view/2118-chilling-cafe


    Background color:       #3a4a58
    Title color:            #996633
    Text color:             #666666
    Text Dark color:        #333333
    Table Heading color:    #C4CDD6
    Table Alternate color:  #e5e8ed 
*/

body {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 17px;
  font-weight: 400;
  margin: 0;
  /* background-image: url("/public/assets/Background.webp"); */
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
}
h1,
h2,
h3 {
  align-content: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1.2rem !important;
  font-weight: 300 !important;
  line-height: 1.4 !important;
}

/* ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.dashboard-container {
  .tm-text-white {
    color: white;
  }
  .tm-text-left {
    text-align: left;
  }
  .dashboard-page-header-container {
    text-align: center;
  }
  width: 100%;
  .dashboard-main-content {
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 20px;
    box-shadow: 0px 4px 42.5px 8px #c2c3c6c9;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    .dashboard-intro-img {
      background-color: rgba(147, 144, 144, 0.4);
      border-radius: 24px;
      margin-left: 10px;
      margin-right: 10px;
      .balance-container {
        margin-left: 10px;
        margin-right: 10px;
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: white;
        .text-color {
          margin: auto;
          color: white;
          font-size: 25px;
          text-align: center;
          opacity: 1;
        }
      }
      .dashboard-button-row {
        display: flex;
        justify-content: space-around; /* Ensures equal spacing between buttons */
        align-items: center; /* Align items vertically */
        .dps-button-container {
          display: flex;
          flex-direction: column; /* Stack button and label vertically */
          align-items: center; /* Center-align each button-label pair */
        }
        .rcv-button-container {
          display: flex;
          flex-direction: column; /* Stack button and label vertically */
          align-items: center; /* Center-align each button-label pair */
        }
        .snd-button-container {
          display: flex;
          flex-direction: column; /* Stack button and label vertically */
          align-items: center; /* Center-align each button-label pair */
        }
        .circular-button {
          width: 60px;
          height: 60px;
          border-radius: 50%; /* Circular shape */
          background-color: rgba(0, 0, 0, 0.68);
          border: none;
          cursor: pointer;
          color: white;
          box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
          font-size: 24px;
          margin-top: 20%;
        }
        .circular-button:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
        .button-label {
          padding-top: 0.5vh;
          color: white;
        }
      }
    }

    .tm-section {
      .tm-section-header {
        margin-top: 20px;
        color: white;
        text-align: center;
      }
      .parent-table {
        height: 43vh;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 1vh;

        .transaction-table {
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 20px;
          width: 17vw;
          margin-right: 1.5vw;
          margin-left: 1.5vw;
          table {
            border: none !important;
          }
          tr,
          td,
          th {
            color: white;
            border: none !important;
          }
        }
      }
    }
  }
}

.first-container {
  .tm-text-white {
    color: white;
  }

  .first-page-header-container {
    text-align: center;
  }
  width: 100%;
  .first-main-content {
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 20px;
    box-shadow: 0px 4px 42.5px 8px #c2c3c6c9;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    .img-rect-container {
      padding: 20px;
      .first-intro-img {
        background-color: rgba(147, 144, 144, 0.4);
        border-radius: 24px;
        text-align: center;
        align-content: center;
        .image-container {
          padding: 20px;
          opacity: 0.7;
        }
      }
    }

    .tm-section {
      align-items: center;
      .parent-table {
        text-align: center;
        width: 200px;
        margin: auto;
        .login-btn {
          border-radius: 20px; /* Circular shape */
          background-color: rgba(0, 0, 0, 0.68);
          cursor: pointer;
          color: white;
          box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
          font-size: 24px;
          width: 80%;
          margin-top: 100px;
          margin-bottom: 19px;
        }
        .register-btn {
          align-content: center;
          border-radius: 20px; /* Circular shape */
          background-color: rgba(0, 0, 0, 0.68);
          border: none;
          cursor: pointer;
          color: white;
          box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
          font-size: 24px;
          margin-top: 20px;
          margin-bottom: 20px;
          width: 80%;
        }
        .register-btn:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
        .login-btn:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}

.login-container {
  .tm-text-white {
    color: white;
  }

  .login-page-header-container {
    text-align: center;
  }
  width: 100%;
  .login-main-content {
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 20px;
    box-shadow: 0px 4px 42.5px 8px #c2c3c6c9;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    .login-intro-img {
      background-color: rgba(147, 144, 144, 0.4);
      border-radius: 24px;
      margin-left: 10px;
      margin-right: 10px;
      align-content: center;
      text-align: center;
      .image-container {
        opacity: 0.4;
      }
    }

    .tm-section {
      width: 300px;
      margin: auto;
      .parent-table {
        text-align: center;
        .email-container {
          width: 100%;
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0.68);
          border: none;
          cursor: pointer;
          color: white;
          box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
          font-size: 24px;
          margin-top: 70px;
        }
        .password-container {
          width: 100%;
          display: block;
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0.68);
          border: none;
          cursor: pointer;
          color: white;
          box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
          font-size: 24px;
          margin-top: 30px;
        }
        .login-btn-container {
          width: 250px;
          text-align: center;
          margin: auto;
          .login-real-btn {
            width: 100%;
            display: block;
            border-radius: 20px; /* Circular shape */
            background-color: rgba(0, 0, 0, 0.68);
            border: none;
            cursor: pointer;
            color: white;
            box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
            font-size: 24px;
            margin-top: 60px;
          }
          .login-real-btn:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }
          .login-return-btn {
            width: 100%;
            display: block;
            border-radius: 20px; /* Circular shape */
            background-color: rgba(0, 0, 0, 0.68);
            border: none;
            cursor: pointer;
            color: white;
            box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
            font-size: 24px;
            margin-top: 20px;
          }
          .login-return-btn:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }
}

.register-container {
  .tm-text-white {
    color: white;
  }

  .register-page-header-container {
    text-align: center;
  }
  width: 100%;
  .register-main-content {
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 20px;
    box-shadow: 0px 4px 42.5px 8px #c2c3c6c9;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    .register-intro-img {
      background-color: rgba(147, 144, 144, 0.4);
      border-radius: 24px;
      margin-left: 10px;
      margin-right: 10px;
      align-content: center;
      text-align: center;
      .image-container {
        opacity: 0.4;
      }
    }

    .tm-section {
      width: 300px;
      margin: auto;
      .parent-table {
        align-items: center;
        .email-container {
          display: block;
          width: 100%;
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0.68);
          border: none;
          cursor: pointer;
          color: white;
          box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
          font-size: 24px;
          margin-top: 15%;
        }
        .password-container {
          display: block;
          width: 100%;
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0.68);
          border: none;
          cursor: pointer;
          color: white;
          box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
          font-size: 24px;
          margin-top: 5%;
        }
        .confirm-password-container {
          display: block;
          width: 100%;
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0.68);
          border: none;
          cursor: pointer;
          color: white;
          box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
          font-size: 24px;
          margin-top: 5%;
        }
        .register-btn-container {
          width: 250px;
          margin: auto;
          .register-real-btn {
            display: block;
            width: 100%;
            border-radius: 20px; /* Circular shape */
            background-color: rgba(0, 0, 0, 0.68);
            border: none;
            cursor: pointer;
            color: white;
            box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
            font-size: 24px;
            margin-top: 12%;
          }
          .register-real-btn:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }

          .register-return-btn {
            display: block;
            width: 100%;
            border-radius: 20px; /* Circular shape */
            background-color: rgba(0, 0, 0, 0.68);
            border: none;
            cursor: pointer;
            color: white;
            box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
            font-size: 24px;
            margin-top: 5%;
          }
          .register-return-btn:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }
}

.deposit-container {
  .tm-text-white {
    color: white;
  }
  .dps-tm-text-white {
    color: white;
    font-size: 25px;
  }

  .deposit-page-header-container {
    text-align: center;
  }
  width: 100%;
  .deposit-main-content {
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 20px;
    box-shadow: 0px 4px 42.5px 8px #c2c3c6c9;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    .deposit-intro-img {
      margin-left: 10px;
      margin-right: 10px;
      background-color: rgba(147, 144, 144, 0.4);
      border-radius: 24px;
      align-content: center;
      text-align: center;
      .image-container {
        margin-top: 10px;
        opacity: 0.7;
      }
    }

    .deposit-section {
      width: 250px;
      margin: auto;
      .parent-table {
        align-items: center;
        .deposit-real-btn {
          display: block;
          width: 100%;
          border-radius: 20px; /* Circular shape */
          background-color: rgba(0, 0, 0, 0.68);
          border: none;
          cursor: pointer;
          color: white;
          box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
          font-size: 24px;
          margin-top: 80px;
        }
        .deposit-real-btn:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
        .deposit-return-btn {
          width: 100%;
          display: block;
          border-radius: 20px; /* Circular shape */
          background-color: rgba(0, 0, 0, 0.68);
          border: none;
          cursor: pointer;
          color: white;
          box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
          font-size: 24px;
          margin-top: 30px;
        }
        .deposit-return-btn:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}

.send-container {
  .tm-text-white {
    color: white;
  }

  .send-page-header-container {
    text-align: center;
  }
  width: 100%;
  .send-main-content {
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 20px;
    box-shadow: 0px 4px 42.5px 8px #c2c3c6c9;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    .send-intro-img {
      background-color: rgba(147, 144, 144, 0.4);
      border-radius: 24px;
      align-content: center;
      text-align: center;
      margin-left: 10px;
      margin-right: 10px;
      .image-container {
        opacity: 0.7;
        .text-color {
          margin-right: 10px;
          margin-left: 10px;
          color: white;
          font-size: 25px;
          text-align: center;
          opacity: 1;
        }
      }
    }

    .send-section {
      width: 300px;
      margin: auto;
      .parent-table {
        align-items: center;
        .recipient-address {
          display: block;
          width: 100%;
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0.68);
          border: none;
          cursor: pointer;
          color: white;
          box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
          font-size: 24px;
          margin-top: 15%;
        }
        .xlm-amount {
          width: 100%;
          display: block;
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0.68);
          border: none;
          cursor: pointer;
          color: white;
          box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
          font-size: 24px;
          margin-top: 5%;
        }
        .send-btn-container {
          width: 250px;
          margin: auto;
          .send-real-btn {
            width: 100%;
            display: block;
            border-radius: 20px; /* Circular shape */
            background-color: rgba(0, 0, 0, 0.68);
            border: none;
            cursor: pointer;
            color: white;
            box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
            font-size: 24px;
            margin-top: 30px;
          }
          .send-real-btn:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }
          .send-return-btn {
            display: block;
            width: 100%;
            border-radius: 20px; /* Circular shape */
            background-color: rgba(0, 0, 0, 0.68);
            border: none;
            cursor: pointer;
            color: white;
            box-shadow: 0px 1px 5px 2px rgba(251, 251, 251, 0.624);
            font-size: 24px;
            margin-top: 30px;
          }
          .send-return-btn:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }
}
